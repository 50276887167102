"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const screenReaderAccessHelper_1 = require("../helpers/screenReaderAccessHelper");
function useIconScreenReaderAccessProps({ isClickable, label, isDecorationOnly }) {
    const screenReaderAccessProps = (0, react_1.useMemo)(() => (0, screenReaderAccessHelper_1.getIconScreenReaderAccessProps)({
        isClickable,
        label,
        isDecorationOnly
    }), [isClickable, label, isDecorationOnly]);
    return screenReaderAccessProps;
}
exports.default = useIconScreenReaderAccessProps;
