"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/*
 * const Component = React.forwardRef((props, ref) => {
 *   const internalRef = React.useRef();
 *   const mergedRef = useMergeRefs({ refs: [ref, internalRef] });
 *   return <div {...props} ref={mergedRef} />;
 * });
 */
function useMergeRefs({ refs = [] }) {
    return (0, react_1.useMemo)(() => {
        if (refs.every(ref => ref === null))
            return null;
        return (node) => {
            refs.forEach(ref => {
                if (ref)
                    assignRef(ref, node);
            });
        };
    }, [refs]);
}
exports.default = useMergeRefs;
function assignRef(ref, value) {
    if (ref === null)
        return;
    if (typeof ref === "function") {
        ref(value);
        return;
    }
    try {
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
    }
    catch (error) {
        console.error(error);
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
}
