"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadingSizes = exports.HeadingTypes = void 0;
const constants_1 = require("../../constants");
var HeadingTypes;
(function (HeadingTypes) {
    HeadingTypes["h1"] = "h1";
    HeadingTypes["h2"] = "h2";
    HeadingTypes["h3"] = "h3";
    HeadingTypes["h4"] = "h4";
    HeadingTypes["h5"] = "h5";
    HeadingTypes["h6"] = "h6";
})(HeadingTypes = exports.HeadingTypes || (exports.HeadingTypes = {}));
exports.HeadingSizes = constants_1.SIZES;
