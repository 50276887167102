"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useRefWithCallback(onMount, onUnmount) {
    const nodeRef = (0, react_1.useRef)(null);
    const setRef = (0, react_1.useCallback)((node) => {
        if (nodeRef.current && onUnmount) {
            onUnmount(nodeRef.current);
        }
        nodeRef.current = node;
        if (nodeRef.current && onMount) {
            onMount(nodeRef.current);
        }
    }, [onMount, onUnmount]);
    return [nodeRef, setRef];
}
exports.default = useRefWithCallback;
