"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipTheme = exports.TooltipArrowPosition = void 0;
var TooltipArrowPosition;
(function (TooltipArrowPosition) {
    TooltipArrowPosition["BEGIN"] = "begin";
    TooltipArrowPosition["CENTER"] = "center";
    TooltipArrowPosition["END"] = "end";
})(TooltipArrowPosition = exports.TooltipArrowPosition || (exports.TooltipArrowPosition = {}));
// Did not convert to letter case naming for backward compatibility
var TooltipTheme;
(function (TooltipTheme) {
    TooltipTheme["Dark"] = "dark";
    TooltipTheme["Success"] = "success";
    TooltipTheme["Error"] = "error";
    TooltipTheme["Share"] = "share";
    TooltipTheme["Private"] = "private";
    TooltipTheme["Surface"] = "surface";
    TooltipTheme["Primary"] = "primary";
})(TooltipTheme = exports.TooltipTheme || (exports.TooltipTheme = {}));
