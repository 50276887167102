"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimationType = exports.HideShowEvent = exports.JustifyType = void 0;
var JustifyType;
(function (JustifyType) {
    JustifyType["START"] = "start";
    JustifyType["CENTER"] = "center";
    JustifyType["END"] = "end";
})(JustifyType = exports.JustifyType || (exports.JustifyType = {}));
var HideShowEvent;
(function (HideShowEvent) {
    HideShowEvent["CLICK"] = "click";
    HideShowEvent["CLICK_OUTSIDE"] = "clickoutside";
    HideShowEvent["ESCAPE_KEY"] = "esckey";
    HideShowEvent["TAB_KEY"] = "tab";
    HideShowEvent["MOUSE_ENTER"] = "mouseenter";
    HideShowEvent["MOUSE_LEAVE"] = "mouseleave";
    HideShowEvent["ENTER"] = "enter";
    HideShowEvent["MOUSE_DOWN"] = "mousedown";
    HideShowEvent["FOCUS"] = "focus";
    HideShowEvent["BLUR"] = "blur";
    HideShowEvent["CONTENT_CLICK"] = "onContentClick";
})(HideShowEvent = exports.HideShowEvent || (exports.HideShowEvent = {}));
var AnimationType;
(function (AnimationType) {
    AnimationType["OPACITY_AND_SLIDE"] = "opacity-and-slide";
    AnimationType["EXPAND"] = "expand";
})(AnimationType = exports.AnimationType || (exports.AnimationType = {}));
