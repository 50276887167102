"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BEMClass = void 0;
function BEMClass(componentName) {
    return ({ element, state }) => {
        let className = componentName;
        if (element)
            className = `${className}_${element}`;
        if (state)
            className = `${className}--${state}`;
        return className;
    };
}
exports.BEMClass = BEMClass;
