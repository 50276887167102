"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWidthHeight = void 0;
const sizes_1 = require("./../../../constants/sizes");
const sizesMap = {
    [sizes_1.SIZES.XXS]: 16,
    [sizes_1.SIZES.XS]: 24,
    [sizes_1.SIZES.SMALL]: 32,
    [sizes_1.SIZES.MEDIUM]: 40,
    [sizes_1.SIZES.LARGE]: 48
};
function getWidthHeight(size) {
    return {
        width: `${sizesMap[size]}px`,
        height: `${sizesMap[size]}px`
    };
}
exports.getWidthHeight = getWidthHeight;
