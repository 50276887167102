"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useEventListener({ eventName, callback, ref, capture = false }) {
    (0, react_1.useEffect)(() => {
        const refElement = ref && ref.current;
        if (!refElement)
            return;
        const listenerOptions = { capture };
        refElement.addEventListener(eventName, callback, listenerOptions);
        return () => {
            refElement.removeEventListener(eventName, callback, listenerOptions);
        };
    }, [eventName, ref, callback, capture]);
}
exports.default = useEventListener;
