"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoaderSizes = exports.LoaderColors = void 0;
var LoaderColors;
(function (LoaderColors) {
    LoaderColors["PRIMARY"] = "var(--primary-color)";
    LoaderColors["SECONDARY"] = "var(--secondary-text-color)";
    LoaderColors["ON_PRIMARY"] = "var(--text-color-on-inverted)";
    LoaderColors["DARK"] = "var(--primary-text-color)";
})(LoaderColors = exports.LoaderColors || (exports.LoaderColors = {}));
var LoaderSizes;
(function (LoaderSizes) {
    LoaderSizes[LoaderSizes["XS"] = 16] = "XS";
    LoaderSizes[LoaderSizes["SMALL"] = 24] = "SMALL";
    LoaderSizes[LoaderSizes["MEDIUM"] = 40] = "MEDIUM";
    LoaderSizes[LoaderSizes["LARGE"] = 64] = "LARGE";
})(LoaderSizes = exports.LoaderSizes || (exports.LoaderSizes = {}));
